.glas-style{
  /* From https://css.glass */
  background: rgb(129 129 129 / 28%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.2px);
  -webkit-backdrop-filter: blur(4.2px);

}
.glas-style-border{
  border: 1px solid rgb(70 70 70 / 30%);
}
