/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "assets/css/typing-inidcator.css";
@import "assets/css/glas.css";

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}


@font-face {
  font-family: 'Manrope';
  src: url('assets/font/Manrope-VariableFont_wght.ttf') format('truetype');
}

.input-box{
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 696px;
}
.porsche-font{
  font-family: "Porsche Next";
}

.eve-font{
  font-family: "Manrope";
}

.custom-modal-backdrop{
  z-index: 20;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}
.custom-modal-dark-backdrop{
  background: rgba(1, 2, 5, 0.50);
  backdrop-filter: blur(64px);
}
.modal-card{
  border-radius: 48px;
  background: rgba(248, 250, 252, 0.10);
  backdrop-filter: blur(40px);
  width: 640px;
  padding: 64px 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
}
.disclaimer-header{
  color: #FFF;

  /* 1440/Headings/M */
  font-family: Manrope;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 64px */
  letter-spacing: -1.28px;
  text-transform: uppercase;
}
.mobile-disclaimer-header{
  font-size: 32px!important;
  margin-bottom: 12px;
}
.disclaimer-text{
  color: rgba(255, 255, 255, 0.60);

  /* 1440/Paragraphs/M */
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  letter-spacing: 0.4px;
}
.modal-action{
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-button{
  display: flex;
  padding: 20px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 256px;
  border: 2px solid #FFF;
  background: #FFF;
}
